import { VALIDATORS } from '../enums/validators';
import validatePhoneNumber from './validatePhoneNumber';
import validateDateOfBirth from '../utils/validateDateOfBirth';
export const DETAILS_FORM = {
  // HA24-426 Commented out as not used, keeping for future use
  date_of_birth: {
    type: 'custom_date',
    label: 'Date of birth',
    required: true,
    validator: VALIDATORS.custom,
    isValid: date => validateDateOfBirth(date),
    defaultValue: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  },
  sex_assigned_at_birth: {
    type: 'select',
    label: 'Sex assigned at birth',
    required: true,
    choices: [
      ['male', 'Male'],
      ['female', 'Female'],
    ],
    placeholder: ' ',
    defaultValue: '',
    helperText: 'Please select your sex assigned at birth',
  },
  gender: {
    type: 'select',
    label: 'Gender',
    required: true,
    choices: [
      ['man', 'Man'],
      ['woman', 'Woman'],
      ['nonBinary', 'Non-binary'],
      ['queer', 'Genderqueer/gender non-conforming'],
      ['preferNotToSay', 'Prefer not to say'],
    ],
    placeholder: ' ',
    defaultValue: '',
    helperText: 'Please select your gender',
  },
};

export const SLOTS_FORM = {
  practitioner_gender: {
    type: 'select',
    label: 'Practitioner‘s gender',
    required: false,
    choices: [
      ['Female', 'Female'],
      ['Male', 'Male'],
      ['Unknown', 'No preference'],
    ],
    placeholder: ' ',
    defaultValue: 'Unknown',
  },
};

const CONFIRM_FORM_BASE = {
  special_requirements: {
    type: 'textarea',
  },
  communication_preferences: {
    type: 'checkboxgroup',
    defaultValue: {
      email: false,
      sms: false,
      phone: false,
    },
    choices: [
      ['email', 'Email'],
      ['sms', 'SMS'],
      ['phone', 'Phone'],
    ],
  },
  addresslookup: {
    type: 'addresslookup-county',
    label: 'Your home address',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => {
      // Check if 'value' and its properties exist before using .trim()
      return (
        value &&
        value.line_one &&
        value.line_one.trim() !== '' &&
        value.city &&
        value.city.trim() !== '' &&
        value.postal_code &&
        value.postal_code.trim() !== ''
      );
    },
  },
};

export const CONFIRM_FORM_PRIMARY_NUMBER = {
  ...CONFIRM_FORM_BASE,
  phone_number: {
    type: 'tel',
    label: 'Mobile number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => Boolean(value) && validatePhoneNumber(value) && value.match(/^0\d+$/),
  },
};

export const CONFIRM_FORM_ALTERNATIVE_NUMBER = {
  ...CONFIRM_FORM_BASE,
  alternative_number: {
    type: 'tel',
    label: 'Alternative phone number',
    validator: VALIDATORS.custom,
    required: true,
    isValid: value => Boolean(value) && validatePhoneNumber(value) && value.match(/^0\d+$/),
    helperText:
      'Please provide a phone number just in case we need to contact you about your booking.',
  },
};

export const CONFIRM_FORM = {};
